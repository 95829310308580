<template>
  <modal
    v-bind="modalData"
    class="modal-bg-gray"
    @close="close"
  >
    <page :data="pageData" :is-child="true">
      <template #cmslefttype(vaddress-listitem)="{ data: d }">
        <list-item
          :data="d.Data"
          @click="$emit('select', d.Data)"
          @title-action-click="({ action }) => handleAction(action, d)"
          @action-click="({ action }) => handleAction(action, d)"
          @footer-action-click="({ action }) => handleAction(action, d)">
          </list-item>
      </template>
      <template #aftercmsleftcomponents>
        <modal-form-page class="modal-bg-gray" v-bind="modalProps('form')" @close="closeForm">
        </modal-form-page>
      </template>
    </page>
  </modal>
</template>
<script>
import ModalCMSPage from '../base/ModalCMSPage'
import ModalFormPage from '../base/ModalFormPage'
export default {
  extends: ModalCMSPage,
  components: {
    ListItem: () => import('@/components/vaddress/ListItem'),
    ModalFormPage
  },
  methods: {
    async handleAction (a, d) {
      if (a.Id === 'delete') {
        await this.$baseApi.post('/user/addresses?Oid=' + d.Oid)
        this.init()
      } else {
        this.edit(d.Data)
      }
    },
    edit (d) {
      if (this.config && this.config.NewError && !d.Oid) {
          this.$buefy.toast.open({
              duration: 3000,
              message: this.config.NewError,
              position: 'is-bottom',
              type: 'is-warning'
          })
        return
      }
      const url = '/user/addresses' + (d.Oid ? `/edit?Oid=${d.Oid}` : '/create')
      this.openModal('form', { url })
    },
    closeForm () {
      this.closeModal('form')
      this.init()
    }
  }
}
</script>

<style>

</style>
