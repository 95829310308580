var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',_vm._b({staticClass:"modal-bg-gray",on:{"close":_vm.close}},'modal',_vm.modalData,false),[_c('page',{attrs:{"data":_vm.pageData,"is-child":true},scopedSlots:_vm._u([{key:"cmslefttype(vaddress-listitem)",fn:function(ref){
var d = ref.data;
return [_c('list-item',{attrs:{"data":d.Data},on:{"click":function($event){return _vm.$emit('select', d.Data)},"title-action-click":function (ref) {
	var action = ref.action;

	return _vm.handleAction(action, d);
},"action-click":function (ref) {
	var action = ref.action;

	return _vm.handleAction(action, d);
},"footer-action-click":function (ref) {
	var action = ref.action;

	return _vm.handleAction(action, d);
}}})]}},{key:"aftercmsleftcomponents",fn:function(){return [_c('modal-form-page',_vm._b({staticClass:"modal-bg-gray",on:{"close":_vm.closeForm}},'modal-form-page',_vm.modalProps('form'),false))]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }